import type { FC } from 'react';
import { ArrayInput, SimpleFormIterator, FormDataConsumer, useTranslate } from 'react-admin';

import { Typography } from '@mui/material';

import { ApmEditProbability } from '../ApmEditProbability';
import { ApmCompartment } from './ApmCompartment';

const ApmEditCompartments: FC = () => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" component="h3" mt={4}>
                {translate('apm.edit.compartments')}
            </Typography>
            <ApmEditProbability mt={3} mb={1} />
            <ArrayInput source="compartments" label={''}>
                <SimpleFormIterator disableAdd disableRemove getItemLabel={() => ''} disableReordering fullWidth>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            if (!getSource || !scopedFormData) {
                                return null;
                            }

                            // @ts-expect-error
                            return <ApmCompartment getSource={getSource} storedParcel={scopedFormData.storedParcel} />;
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

export default ApmEditCompartments;
